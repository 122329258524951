import PageRequest from '../../../../PaginationHelper/Lib/PageRequest';
import SortingModelT from '../../../../Common/Models/SortingModelT';
import EnumSortingUsersWithTheirEndpoints from '../../../../AnalyticUsers/IService/Models/EnumSortingUsersWithTheirEndpoints';

export default class UsersWithTheirEndpointsRequest  {
	ProjectLogGroupId?: number = null;
	FromDate: Date = null;
	ToDate: Date = null;
	Page: PageRequest = null;
	// SortingModel: SortingModelT<EnumSortingUsersWithTheirEndpoints> = null;
	constructor(obj?: Partial<UsersWithTheirEndpointsRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
