<template>
  <page :name="'Список пользователей'" :selectSource="false">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>

    <!--   Фильтрация   -->
    <template v-slot:toolbar>
      <filter-component
          v-model:filter="filter"
          :selectInterval="false"
          :selectLogsFilter="false"
          :selectViewFilter="false"
          @filterData="filterData"
      ></filter-component>
    </template>

    <!--  Таблица  -->
    <tirscript3-table
        :items="usersWithTheirEndpointsResponse"
        style="overflow-x: auto"
        template-columns="auto auto auto"
    >
      <template #header
      >
        <tirscript3-table-header-item :defaultSort="null"
        >#
        </tirscript3-table-header-item
        >
        <tirscript3-table-header-item :defaultSort="null"
        >Кол-во вызванных методов
        </tirscript3-table-header-item>
        <tirscript3-table-header-item :defaultSort="null"
        >Общее время загрузки на сайте
        </tirscript3-table-header-item
        >
      </template>

      <template #body="usersWithTheirEndpointsResponse">
        <tirscript3-table-body-item>{{
            usersWithTheirEndpointsResponse.item.UserId
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            usersWithTheirEndpointsResponse.item.NumberOfCallsAllMethods
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            usersWithTheirEndpointsResponse.item.WorkingTimeAllMethods
          }}
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>

    <!--  Пагинация  -->
    <tirscript3-pagination
        v-show="pageResponse.Count > 0"
        ref="pagination"
        :count="pageResponse.Count"
        :size="pageResponse.Size"
        class="mt-2"
        @onChange="changePage"
    ></tirscript3-pagination>

    <div
        v-if="!error.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error.errorMessage }}
    </div>
  </page>
</template>
<script lang="ts">
import {Options, Vue} from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import FilterModel from "@/views/page/components/filter-model";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import UsersWithTheirEndpointsResponse
  from "@/dataSource/api/AnalyticUsers/IService/Models/Response/UsersWithTheirEndpointsResponse";
import UsersWithTheirEndpointsResponseModel
  from "@/dataSource/api/AnalyticUsers/IService/Models/Response/UsersWithTheirEndpointsResponseModel";
import UsersWithTheirEndpointsRequest
  from "@/dataSource/api/AnalyticUsers/IService/Models/Request/UsersWithTheirEndpointsRequest";
import getUTC from "@/function/getUTC"

@Options({
  name: "UserList",
  components: {
    Page,
  },
})
export default class UserList extends Vue {
  filter: FilterModel = new FilterModel();
  usersWithTheirEndpointsRequest = new UsersWithTheirEndpointsRequest();
  usersWithTheirEndpointsResponse: Array<UsersWithTheirEndpointsResponseModel> =
      new Array<UsersWithTheirEndpointsResponseModel>();
  pageResponse: PageResponse = new PageResponse();
  skipPage: number = 0;
  takePage: number = 20;
  sortType: number = 1;
  isLoading: boolean = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  filterData() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.getData();
  }

  async getData() {
    this.isLoading = true;
    this.usersWithTheirEndpointsRequest = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: {Skip: this.skipPage, Take: this.takePage},
    };
    let res: UsersWithTheirEndpointsResponse;
    let tmpFilter = getUTC(this.usersWithTheirEndpointsRequest)
    try {
      res =
          await this.$api.AnalyticUsersService.getUsersWithTheirEndpointsAsync(
              tmpFilter
          );
    } catch (error) {
      this.error.isSuccess = false
      this.error.errorMessage = error
      this.isLoading = false;
    }
    this.usersWithTheirEndpointsResponse = res.Endpoints;
    this.pageResponse = res.Page;
    this.isLoading = false;
  }

  changePage(page) {
    this.skipPage = (page - 1) * this.takePage;
    this.getData();
  }
}
</script>

<style lang="less">
.toolbar {
  display: flex;
  justify-content: space-between;

  .tirscript-dropdown {
    flex: 0 1 45%;
    margin-right: 10px;

    &:first-child {
      margin: 0;
    }
  }

  .date-wrapper {
    margin: 0 20px;
    display: flex;
    flex: 0 1 45%;

    .tirscript-date-picker {
      flex: 0 1 50%;
    }
  }

  .tirscript-button {
    flex: 0 0 8%;
    margin-left: 10px;
  }
}

.table-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>